const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';

function getLocalPreselection(pageType, defaults) {
  const isCategoryPage = pageType === 'category';
  const isBrandPage = pageType === 'brand';

  if (
    (isCategoryPage || isBrandPage) &&
    defaults.termFromBreadcrumbs !== 'Shop' &&
    defaults.termFromBreadcrumbs.startsWith('Shop')
  ) {
    const trimmedTermFromBreadcrumbs = defaults.termFromBreadcrumbs.replace('Shop>', '');
    return [
      {
        field: isCategoryPage ? 'category' : 'brand_name',
        term: trimmedTermFromBreadcrumbs,
        treeLevel: isCategoryPage ? trimmedTermFromBreadcrumbs.split('>').length - 1 : null,
      },
    ];
  }

  return defaults.getter(pageType);
}

export default {
  platform: 'bigcommerce',
  getLocalPreselection,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    // categorySelectionPageRedirect: true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { replace: 'main .page-content' },
      template: 'SearchPage',
      visibleIf: () => window.location.pathname === '/search/',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '.body .page',
      template: 'SearchPage',
      visibleIf: () =>
        window.document.querySelector(
          '[data-content-region=category_below_header], [data-content-region=brand_below_header]',
        ) &&
        window.location.pathname !== categorySelectionPageUrl &&
        window.location.pathname !== brandSelectionPageUrl,
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'date_created:desc': 'Newest',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'review_score:desc': 'Customer Review',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
      initCollapsed: true,
      initExpandedFacets: ['category'],
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: '.header-search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { selector: '.quick-search-container' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { insertAfter: '.heroCarousel', class: 'cm_vehicle-widget__home-container' },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 550,
    },
    {
      name: 'HeaderVehicleWidget_garage',
      type: 'VehicleWidget',
      location: {
        replace: '#vehicle-list',
        id: 'vehicle-list',
        class: 'cm_vehicle-widget__garage',
      },
      template: 'fitmentSearch/collapsibleSingleVehicleGarage',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { lastChildOf: '.productView-details.product-options' },
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 550,
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        insertAfter: '.productView-description .tabs li:first-child',
        wrapper: 'li',
        class: 'tab',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.productView-description .tabs-contents',
        class: 'tab-content is-closed',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.body .page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '.body .page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      visibleIf: () => window.location.pathname === brandSelectionPageUrl,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      initExpandedFacets: ['category'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
