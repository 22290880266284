//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-436:_3352,_4060,_8912,_1144,_7048,_8456,_3204,_3380;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-436')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-436', "_3352,_4060,_8912,_1144,_7048,_8456,_3204,_3380");
        }
      }catch (ex) {
        console.error(ex);
      }